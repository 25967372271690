import React from 'react';
import classnames from 'classnames';
import { useStyles } from '../Toolbar.styles';
import { Transforms } from 'slate';
import { TYPES } from '../../utils/SlateUtilityFunctions';
import { useMediaQuery } from '@material-ui/core';
interface ToolBarVariableButtonProps {
  editor: any;
  group: any[];
}

export const ToolBarVariableButton: React.FC<ToolBarVariableButtonProps> = ({
  editor,
  group,
}) => {
  const isDesktop = useMediaQuery('(min-device-width: 767px)');
  const classes = useStyles({ toolbarPosition: 'top', isDesktop });
  return group.map(({ id, label, value, secondaryColor }) => (
    <div
      key={id}
      className={classnames(
        classes.variableButtons,
        secondaryColor && classes.secondaryButton
      )}
      onClick={(event: { preventDefault: () => void }) => {
        Transforms.insertNodes(editor, {
          type: TYPES.CHIP,
          children: [{ text: '' }],
          value: value,
          character: label,
          secondaryColor: secondaryColor,
        });
        Transforms.insertText(editor, ' ');
        Transforms.move(editor);
      }}
    >
      {label}
    </div>
  ));
};
