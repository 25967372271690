import React from 'react';
import classnames from 'classnames';
import { useStyles } from './../../Elements/VariableChip/variableChip.styles';

interface VariableChipSerializeProps {
  attributes: any;
  element: any;
  value: string;
  variableChips?: any;
  chipStyles?: React.CSSProperties;
}

const VariableChipSerialize: React.FC<VariableChipSerializeProps> = (props) => {
  const { element, attributes, variableChips, chipStyles } = props;
  const { chips } = variableChips || {};

  const elementOption =
    chips?.find((chip: any) => chip.value === element.value) || element;

  const css = useStyles();

  return (
    <>
      <cite
        contentEditable={false}
        chipLabel={props.element.character}
        chipValue={props.element.value}
        className={classnames(
          css.variableButtons,
          elementOption.secondaryColor && css.secondaryButton
        )}
        {...attributes}
        {...element.attr}
        {...props}
        label={elementOption.label}
        style={{ fontStyle: 'normal', ...chipStyles }}
      >
        {elementOption.value}
      </cite>
    </>
  );
};

export default VariableChipSerialize;
