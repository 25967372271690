import React from 'react';
import MobileModal from './../../../MobileModal/MobileModal';
import { useStyles } from './VariableChipMobileModal.styles';

interface BottomDrawerProps {
  showPopup: Boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const BottomDrawer: React.FC<BottomDrawerProps> = ({
  showPopup,
  onClose,
  children,
}) => {
  const css = useStyles();

  return (
    <MobileModal
      open={showPopup}
      onClose={() => onClose()}
      header="Personalise"
      customFooter={<></>}
    >
      <div className={css.contenModalDrawer}>{children}</div>
    </MobileModal>
  );
};

export default BottomDrawer;
