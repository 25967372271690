import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  contenModalDrawer: {
    background: theme?.palette?.basic?.white,
    display: "flex",
    gap: "12px",
    padding: "16px 14px",
    flexWrap: "wrap",
  }
}));