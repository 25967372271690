import { Box, Popover } from '@material-ui/core';
import React, { useState } from 'react';
import { Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import CloseIcon from '@material-ui/icons/Close';
import { ToolBarVariableButton } from './ToolBarVariableButton';
import VariableChipMobileModal from './../../Elements/VariableChipMobileModal/VariableChipMobileModal';
import { useMediaQuery } from '@material-ui/core';

interface ButtonDropdownProps {
  options: any[];
  editor: any;
  placeholder?: any;
  minWidth?: string;
  classes: any;
}

export const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
  options,
  editor,
  placeholder,
  minWidth,
  classes,
  ...restProps
}) => {
  const { selection } = editor;
  const isDesktop = useMediaQuery('(min-device-width: 767px)');

  const [open, setOpen] = useState(false);
  const [anchroEl, setAnchroEl] = useState(null);
  const [editorSelection, setEditorSelection] = useState(null);

  const setFocus = (selection: any) => {
    if (selection)
      setTimeout(() => {
        if (Range.isCollapsed(selection)) {
          Transforms.select(editor, selection);
          ReactEditor.focus(editor);
        }
      }, 50);
  };

  const onClose = () => setOpen(false);

  const handleOpen = (e) => {
    e.preventDefault();
    setEditorSelection(selection);
    setAnchroEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setAnchroEl(null);
    setOpen(false);
    setFocus(editorSelection);
  };

  return (
    <Box className={classes.complexDropdown}>
      <Box
        className={classes.dropdownLabelPersonalize}
        onMouseDown={handleOpen}
      >
        {placeholder}
      </Box>
      {isDesktop ? (
        <Popover
          id="VaribaleButtonSelectorDropdown"
          open={open}
          anchorEl={anchroEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: classes.variableButtonsPopoverPaper,
          }}
        >
          <div className={classes.variableButtonsContainer}>
            <ToolBarVariableButton group={options} editor={editor} />
            <CloseIcon className={classes.closeIcon} onClick={onClose} />
          </div>
        </Popover>
      ) : (
        <VariableChipMobileModal showPopup={open} onClose={onClose}>
          <ToolBarVariableButton group={options} editor={editor} />
        </VariableChipMobileModal>
      )}
    </Box>
  );
};
