import { TYPES } from '../utils/SlateUtilityFunctions';

export const withVariableChips = (editor: { isInline: any; isVoid: any; markableVoid: any; }) => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = (element: { type: string; }) => {
    return element.type === TYPES.CHIP ? true : isInline(element)
  }

  editor.isVoid = (element: { type: string; }) => {
    return element.type === TYPES.CHIP ? true : isVoid(element)
  }

  editor.markableVoid = (element: { type: string; }) => {
    return element.type === TYPES.CHIP || markableVoid(element)
  }

  return editor
}