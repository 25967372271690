import React, { useEffect, useState } from 'react';
import { useStyles } from './BottomDrawer.styles';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMediaQuery } from '@material-ui/core';
import VariableChipMobileModal from './../VariableChipMobileModal/VariableChipMobileModal';
import classnames from 'classnames';

interface BottomDrawerProps {
  title: string;
  children: React.ReactNode;
  isVariableDrawerOpen: Boolean;
  onCloseBottomBarDrawer: () => void;
  drawer?: string;
}

const BottomDrawer: React.FC<BottomDrawerProps> = ({
  title,
  children,
  isVariableDrawerOpen = false,
  drawer,
  onCloseBottomBarDrawer,
}) => {
  const [showPopup, hidePopup] = useState(isVariableDrawerOpen);
  const css = useStyles({ drawer });
  const isDesktop = useMediaQuery('(min-device-width: 767px)');

  useEffect(() => {
    hidePopup(isVariableDrawerOpen);
  }, [isVariableDrawerOpen]);

  const onClose = () => {
    hidePopup(false);
    onCloseBottomBarDrawer && onCloseBottomBarDrawer();
  };

  if (!isDesktop) {
    return (
      <VariableChipMobileModal showPopup={showPopup} onClose={onClose}>
        {children}
      </VariableChipMobileModal>
    );
  }

  return (
    <div className={css.BottomDrawer}>
      {showPopup && (
        <div className={classnames(css.BottomDrawercontent, drawer)}>
          {children}
          <IconButton
            disableRipple
            disableFocusRipple
            component="span"
            onClick={onClose}
            className={css.CollapseDrawer}
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default BottomDrawer;
