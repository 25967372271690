import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  BottomDrawer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  BottomDrawercontent: {
    position: "absolute",
    bottom: "1px",
    background: theme?.palette?.basic?.white,
    boxShadow: "0px -3px 6px rgba(0, 0, 0, 0.2)",
    borderRadius: "4px 4px 0px 0px",
    width: "100%",
    display: "flex",
    gap: "8px",
    padding: "16px 14px",
    flexWrap: "wrap",
    fontSize: "12px"
  },
  CollapseDrawer: {
    height: "24px",
    width: "24px",
    color: theme?.palette?.secondary?.main,
    padding: "0",
    // marginLeft: "auto",
    position: "absolute",
    top: "14px",
    right: "8px",
  },
  contenModalDrawer: {
    background: theme?.palette?.basic?.white,
    display: "flex",
    gap: "12px",
    padding: "16px 14px",
    flexWrap: "wrap",
  }
}));
