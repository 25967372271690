import { jsx } from 'slate-hyperscript';
import {
  fontFamilyMap,
  getJsxStyles,
  sizeMap,
  TYPES,
} from './SlateUtilityFunctions';
import { KEYS } from '../constants';

/**
 * Deserialize HTML elements into Slate JSX elements.
 * @param {any} el - The HTML element to deserialize.
 * @param {Object} markAttributes - Additional attributes.
 * @returns {Array} - An array of Slate JSX elements.
 */
export const deserialize = (el: any, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes: any = { ...markAttributes };

  const styles: any = getJsxStyles(el.attributes.style?.value);

  // define attributes for text nodes
  switch (el.nodeName.toLowerCase()) {
    case KEYS.strong:
    case KEYS.b:
      nodeAttributes.bold = true;
      break;
    case KEYS.em:
    case KEYS.i:
      nodeAttributes.italic = true;
      break;
    case KEYS.code:
      nodeAttributes.code = true;
      break;
    case KEYS.u:
      nodeAttributes.underline = true;
      break;
    case KEYS.del:
      nodeAttributes.strikethrough = true;
      break;
  }

  if (styles.color) nodeAttributes.color = styles.color;

  if (styles.fontSize) {
    const sizeType = Object.keys(sizeMap).find(
      (type) => sizeMap[type] === styles.fontSize
    );
    nodeAttributes.fontSize = sizeType;
  }

  if (styles.fontFamily) {
    const fontFamilyType = Object.keys(fontFamilyMap).find(
      (type) => fontFamilyMap[type] === styles.fontFamily
    );
    nodeAttributes.fontFamily = fontFamilyType;
  }

  if (styles.backgroundColor) nodeAttributes.bgColor = styles.backgroundColor;

  if (
    styles.fontWeight === KEYS.bold ||
    styles.fontWeight === KEYS.sevenHundred
  ) {
    nodeAttributes.bold = true;
  }
  if (styles.fontStyle === KEYS.italic) nodeAttributes.italic = true;

  if (styles.textDecoration) {
    const textDecorationStyles = styles.textDecoration.split(' ');
    textDecorationStyles.forEach((styleMap: string) => {
      if (styleMap === KEYS.underline) nodeAttributes.underline = true;
      if (styleMap === KEYS.lineThrough) nodeAttributes.strikethrough = true;
    });
  }

  const children: any = Array.from(el.childNodes)
    .map((node) => deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, KEYS.emptyString));
  }

  if (el.nodeName == KEYS.A) {
    const imgEl = children[0];

    if (imgEl.type === TYPES.IMAGE) {
      const { justifyContent } = getJsxStyles(imgEl.style?.value);

      return jsx(
        'element',
        {
          type: TYPES.IMAGE,
          url: imgEl.url,
          alt: imgEl.alt,
          style: imgEl.style,
          redirectTo: el.getAttribute('href'),
          justifyContent,
        },
        imgEl.children
      );
    }
  }

  switch (el.nodeName) {
    case KEYS.BODY:
      return jsx('fragment', {}, children);
    case KEYS.BR:
      return jsx('element', { style: el.attributes.style }, [
        { text: KEYS.emptyString },
      ]);
    case KEYS.BLOCKQUOTE:
      return jsx(
        'element',
        { type: TYPES.BLOCKQUOTE, style: el.attributes.style },
        children
      );
    case KEYS.DIV: {
      const { textAlign } = getJsxStyles(el.attributes.style?.value);

      const alignType = {
        center: TYPES.ALIGN_CENTER,
        right: TYPES.ALIGN_RIGHT,
      };

      return jsx(
        'element',
        {
          type:
            el.parentElement?.nodeName === KEYS.LI
              ? TYPES.LIST_ITEM_TEXT
              : alignType[textAlign] || TYPES.ALIGN_LEFT,
          style: el.attributes.style,
        },
        children
      );
    }
    case KEYS.P:
      return jsx(
        'element',
        { type: TYPES.PARAGRAPH, style: el.attributes.style },
        children
      );
    case KEYS.H1:
      return jsx(
        'element',
        { type: TYPES.H1, style: el.attributes.style },
        children
      );
    case KEYS.H2:
      return jsx(
        'element',
        { type: TYPES.H2, style: el.attributes.style },
        children
      );
    case KEYS.H3:
      return jsx(
        'element',
        { type: TYPES.H3, style: el.attributes.style },
        children
      );
    case KEYS.H4:
      return jsx(
        'element',
        { type: TYPES.H4, style: el.attributes.style },
        children
      );
    case KEYS.H5:
      return jsx(
        'element',
        { type: TYPES.H5, style: el.attributes.style },
        children
      );
    case KEYS.H6:
      return jsx(
        'element',
        { type: TYPES.H6, style: el.attributes.style },
        children
      );
    case KEYS.IMG: {
      const { justifyContent } = getJsxStyles(el.attributes.style?.value);
      return jsx(
        'element',
        {
          type: TYPES.IMAGE,
          url: el.getAttribute('src'),
          alt: el.getAttribute('alt'),
          style: el.attributes.style,
          justifyContent,
        },
        children
      );
    }
    case KEYS.A:
      return jsx(
        'element',
        {
          type: TYPES.LINK,
          href: el.getAttribute(KEYS.href),
          style: el.attributes.style,
        },
        children
      );
    case KEYS.UL:
      return jsx(
        'element',
        {
          type: TYPES.UNORDERED_LIST,
          href: el.getAttribute(KEYS.href),
          style: el.attributes.style,
        },
        children
      );
    case KEYS.OL:
      return jsx(
        'element',
        {
          type: TYPES.ORDERED_LIST,
          href: el.getAttribute(KEYS.href),
          style: el.attributes.style,
        },
        children
      );
    case KEYS.LI:
      return jsx(
        'element',
        {
          type: TYPES.LIST_ITEM,
          href: el.getAttribute(KEYS.href),
          style: el.attributes.style,
        },
        children
      );
    case KEYS.TABLE:
      return jsx(
        'element',
        {
          type: TYPES.TABLE,
          style: el.attributes.style,
        },
        children
      );
    case KEYS.TBODY:
      return jsx(
        'element',
        {
          type: TYPES.TABLE_BODY,
          style: el.attributes.style,
        },
        children
      );
    case KEYS.TR:
      return jsx(
        'element',
        {
          type: TYPES.TABLE_ROW,
          style: el.attributes.style,
        },
        children
      );
    case KEYS.TH:
      return jsx(
        'element',
        {
          type: TYPES.TABLE_HEAD,
          style: el.attributes.style,
        },
        children
      );
    case KEYS.TD:
      return jsx(
        'element',
        {
          type: TYPES.TABLE_CELL,
          style: el.attributes.style,
        },
        children
      );
    case KEYS.CITE:
      const chipLabel = el.getAttribute('chipLabel');
      const chipValue = el.getAttribute('chipValue');
      const sanitizedString = el.textContent.replace(
        /[^a-zA-Z_-]/g,
        KEYS.emptyString
      );
      return jsx(
        'element',
        {
          type: TYPES.CHIP,
          style: el.attributes.style,
          label: chipLabel || sanitizedString,
          value: chipValue || sanitizedString,
          content: chipLabel,
          character: chipLabel,
        },
        sanitizedString
      );
    default:
      return children;
  }
};

export const deserializer = (html: string, disableWhiteSpaces = true) => {
  let body = (html || '<p></p>').trim();

  if (disableWhiteSpaces) body = body.replace(/>\s+</g, '><');

  const document = new DOMParser().parseFromString(body, 'text/html');
  return deserialize(document.body);
};
