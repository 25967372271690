import { onKeyDown } from '@prezly/slate-lists';
import classnames from 'classnames';
import { Range, Transforms, Editor, Element } from 'slate';
import isHotkey, { isKeyHotkey } from 'is-hotkey';
import React, { useMemo, useState } from 'react';
import { Editable, Slate } from 'slate-react';
import withGenerateClassName from '../../../themes/withGenerateClassName';
import CodeToText from './Elements/CodeToText/CodeToText';
import { createSlateEditor } from './plugins';
import { useStyles } from './RichTextEditorV2.styles';
import Toolbar from './Toolbar/Toolbar';
import {
  HOTKEYS,
  renderElement,
  renderLeaf,
  toggleMark,
  TYPES,
} from './utils/SlateUtilityFunctions.js';
import BottomDrawer from './Elements/BottomDrawer/BottomDrawer';
import { ToolBarVariableButton } from './Toolbar/components/ToolBarVariableButton';
import {
  convertStringToCSSObject,
  flattenHtmlWithStyles,
} from './utils/FilterPasteElements/filterPasteElements';
import { allowedStylesOnPaste } from './RichTextEditorV2.utils';

interface RTEProps {
  editorState: any;
  onEditorStateChange: Function;
  toolbarPosition: 'top' | 'bottom';
  showBorder: boolean;
  isSmallToolbar: boolean;
  minHeight?: string;
  maxHeight?: string;
  padding?: string;
  slateProps?: any;
  editableProps?: any;
  editableWrapperProps?: any;
  rootWrapperProps?: any;
  rootWrapperClassName?: string;
  editableWrapperClassName?: string;
  additionalToolbarActions?: any[];
  customToolbarClassName?: string;
  onFocus?: Function;
  onBlur?: Function;
  codeToTextTextareaProps?: any;
  imageUploadCallback?: (val: React.SyntheticEvent | string) => null;
  cursorPlacementTimeout?: number;
  autoFocus?: boolean;
  variableChips?: any;
  readOnly?: boolean;
  showToolbar: boolean;
  showChipsOnReadOnly?: boolean;
  isBoxDisabled: boolean;
  removeStylesOnPaste: boolean;
  pasteOnlyText?: boolean;
  filterToolbarGroups?: string[];
}

export interface HtmlActionProps {
  showInput?: boolean;
  html?: string;
  action?: string;
  location?: string;
}

const RichTextEditorV2: React.FC<RTEProps> = ({
  editorState: value,
  onEditorStateChange: onChange,
  showBorder = true,
  minHeight,
  maxHeight,
  padding,
  isSmallToolbar,
  toolbarPosition = 'top',
  slateProps,
  editableProps,
  editableWrapperProps,
  rootWrapperProps,
  onFocus,
  onBlur,
  rootWrapperClassName,
  editableWrapperClassName,
  additionalToolbarActions,
  customToolbarClassName,
  codeToTextTextareaProps,
  imageUploadCallback,
  cursorPlacementTimeout = 300,
  autoFocus,
  variableChips = {},
  showToolbar = true,
  showChipsOnReadOnly = false,
  isBoxDisabled = false, //this prop is used to check if its a normal rte or rte displaye for an rte modal
  removeStylesOnPaste = false,
  pasteOnlyText = true,
  filterToolbarGroups = [],
  ...restProps
}) => {
  const { readOnly } = restProps;
  const isReadOnly = readOnly || isBoxDisabled;

  const classes = useStyles({
    showBorder,
    toolbarPosition,
    minHeight,
    maxHeight,
    padding,
    isBoxDisabled,
  });

  const editor = useMemo(() => createSlateEditor(), []);

  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf('android') > -1; //&& ua.indexOf("mobile");

  setTimeout(() => {
    const editorEl = document.querySelector<HTMLDivElement>(
      '[data-slate-editor="true"]'
    );

    if (!editorEl) return;

    editorEl.style.minHeight = minHeight || `153px`;
    editorEl.style.maxHeight = maxHeight || `auto`;
  }, cursorPlacementTimeout);

  const EmptyState = [{ type: TYPES.PARAGRAPH, children: [{ text: '' }] }];
  editor.children = value || EmptyState;

  const [htmlAction, setHtmlAction] = useState<HtmlActionProps>({
    showInput: false,
    html: '',
    action: '',
    location: '',
  });

  const handleCodeToText = (partialState: HtmlActionProps) => {
    setHtmlAction((prev) => ({
      ...prev,
      ...partialState,
    }));
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event as any)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }
  };

  function handlePaste(event: React.ClipboardEvent<HTMLDivElement>) {
    /* 
      Remove Styles on paste removes all styles from the copied content
      if removeStylesOnPaste is false -> and pasteOnlyText is true -> then pasting behaviour will be filtering out images and keeping text with its styles.
      if both are false then the pasting behaviour is default.
    */
    if (removeStylesOnPaste) {
      handlePasteRemoveStyles(event);
    } else {
      if (pasteOnlyText) {
        handlePasteOnlyText(event);
      }

      handleChipsPaste(event);
    }
  }

  //has to be inside slatejs as we are using Transforms
  function handlePasteRemoveStyles(
    event: React.ClipboardEvent<HTMLDivElement>
  ) {
    event.preventDefault();
    const formattedText = event.clipboardData.getData('text/plain');

    Transforms.insertText(editor, formattedText);
  }
  /**
   * Check if there is text alignment or anchor tags present in the given HTML data.
   * @param {string} htmlData - The HTML data to be checked.
   * @returns {boolean} True if text alignment is present and there are no anchor tags, otherwise false.
   */
  function isTextAlignmentOnPasting(htmlData: string) {
    // Check if the 'alignment' feature is enabled in the toolbar
    if (
      filterToolbarGroups.length &&
      filterToolbarGroups.includes('alignment')
    ) {
      // Create a temporary DOM element to work with the HTML data
      const tempElement = document.createElement('div');
      tempElement.innerHTML = htmlData;

      // Check if any elements have 'text-align' styles applied
      const elementsWithTextAlign = tempElement.querySelectorAll(
        '[style*="text-align"]'
      );

      // Check if the HTML content contains anchor tags
      const anchorTags = tempElement.querySelectorAll('a');

      /**
       *  If anchor tags are found, return false,
       *  because in handlePasteOnlyText, changes the anchor "<a>" to "<p>"
       *  and that will remmove the functionality of anchor tag and converted to paragraph
       */
      if (anchorTags.length > 0) {
        return false;
      }

      // If no anchor tags are found, check if 'text-align' styles are present
      return elementsWithTextAlign.length > 0;
    }

    // If the 'alignment' feature is not enabled, return false
    return false;
  }

  function handlePasteOnlyText(event: React.ClipboardEvent<HTMLDivElement>) {
    let htmlData = event.clipboardData.getData('text/html');

    const containsImages = htmlData && /<img[\s\S]*?>/i.test(htmlData);
    // checking for the alignment style in htmlString when we remove the Alignment from the RTE toolbar
    //then have to remove the text-align style from the html String
    if (
      pasteOnlyText &&
      (containsImages || isTextAlignmentOnPasting(htmlData))
    ) {
      event.preventDefault();
      let allowedStyles = [...allowedStylesOnPaste];
      if (
        filterToolbarGroups.length &&
        filterToolbarGroups.includes('alignment')
      ) {
        allowedStyles = allowedStylesOnPaste.filter(
          (style) => style !== 'text-align'
        );
      }
      const filteredData = flattenHtmlWithStyles(htmlData, allowedStyles);
      const div = document.createElement('div');
      div.innerHTML = filteredData;

      const children = Array.from(div.children);

      // TODO: check if this can be converted as RTE state, and inserted at once.
      for (const child of children) {
        if (child.textContent) {
          Transforms.insertNodes(
            editor,
            {
              type: TYPES.PARAGRAPH,
              children: [{ text: child.textContent || '' }],
              styles: convertStringToCSSObject(
                child.getAttribute('style') || ''
              ),
            },
            {
              select: true,
            }
          );
        }
      }
    }
  }

  function findChipByLabel(label: string, chips: any[]) {
    return chips.find(
      (chip: { label: string }) =>
        chip.label.toLowerCase() === label.toLowerCase()
    );
  }

  function handleChipsPaste(event: React.ClipboardEvent<HTMLDivElement>) {
    const htmlData = event.clipboardData
      .getData('text/html')
      .replace(/<meta[\s\S]*?>\s*/gi, '');
    const containsCiteTags =
      htmlData && /<cite[\s\S]*?>.*?<\/cite>/i.test(htmlData);
    const div = document.createElement('div');
    div.innerHTML = htmlData;

    function processNode(node: Node, level: number = 0): Node[] {
      const nodesToInsert: Node[] = [];
      if (node instanceof HTMLElement) {
        if (node.tagName === 'CITE') {
          const chipData = findChipByLabel(
            node.textContent || '',
            variableChips.chips
          );

          nodesToInsert.push({
            type: TYPES.CHIP,
            children: [{ text: '' }],
            value: chipData.value,
            character: chipData.label,
          });
        } else {
          const newLevel = level + 1;
          for (const childNode of node.childNodes) {
            nodesToInsert.push(...processNode(childNode, newLevel));
            if (newLevel === 1 && childNode.tagName === 'P') {
              nodesToInsert.push({ text: '\n' });
            }
          }
        }
      } else if (node instanceof Text) {
        const text = node.textContent;
        if (text) {
          nodesToInsert.push({ text: text });
        }
      }

      return nodesToInsert;
    }

    if (containsCiteTags) {
      const nodesToInsert = processNode(div);
      if (nodesToInsert.length > 0) {
        const paragraphNode: Node = {
          type: TYPES.PARAGRAPH,
          children: nodesToInsert,
        };

        Transforms.insertNodes(editor, paragraphNode);
      }

      event.preventDefault();
    }
  }

  return (
    <div
      className={classnames(classes.root, rootWrapperClassName)}
      {...rootWrapperProps}
    >
      <Slate
        editor={editor}
        value={value || EmptyState}
        onChange={onChange}
        {...slateProps}
        {...restProps}
      >
        {!isReadOnly && showToolbar && (
          <Toolbar
            handleCodeToText={handleCodeToText}
            toolbarPosition={toolbarPosition}
            isSmallToolbar={isSmallToolbar}
            additionalToolbarActions={additionalToolbarActions}
            customToolbarClassName={customToolbarClassName}
            imageUploadCallback={imageUploadCallback}
            variableChips={variableChips}
            filterToolbarGroups={filterToolbarGroups}
          />
        )}
        <div
          className={classnames(classes.editable, editableWrapperClassName)}
          {...editableWrapperProps}
        >
          <Editable
            placeholder="Write something"
            renderElement={(props) =>
              renderElement({
                ...props,
                variableChips,
                readOnly: isReadOnly,
                showChipsOnReadOnly,
              })
            }
            autoFocus={autoFocus}
            renderLeaf={renderLeaf}
            onPaste={handlePaste}
            autoCorrect="false"
            spellCheck="false"
            autoComplete="false"
            autoCapitalize="none"
            keyboardType="visible-password"
            onKeyDown={(event) => {
              onKeyDown(editor, event);

              handleKeyDown(event);

              const { selection } = editor;

              if (selection && Range.isCollapsed(selection)) {
                const { nativeEvent } = event;
                if (isKeyHotkey('left', nativeEvent)) {
                  event.preventDefault();
                  Transforms.move(editor, { unit: 'offset', reverse: true });
                  return;
                }
                if (isKeyHotkey('right', nativeEvent)) {
                  event.preventDefault();
                  Transforms.move(editor, { unit: 'offset' });
                  return;
                }
              }
            }}
            onDOMBeforeInput={(event) => {
              if (isAndroid) {
                const {
                  // isComposing,
                  inputType,
                } = event;

                if (inputType === 'deleteContentBackward') {
                  event.preventDefault();
                  Transforms.delete(editor, {
                    unit: 'character',
                    distance: 1,
                    reverse: true,
                  });

                  return;
                }
              }
            }}
            {...editableProps}
            {...restProps}
            readOnly={isReadOnly}
          />
        </div>
        {htmlAction.showInput && (
          <CodeToText
            {...htmlAction}
            handleCodeToText={handleCodeToText}
            codeToTextTextareaProps={codeToTextTextareaProps}
          />
        )}
      </Slate>

      {variableChips.includeInBottomBar && !isBoxDisabled && (
        <BottomDrawer
          title="Personalise"
          isVariableDrawerOpen={variableChips?.openBottomBarVariableDrawer}
          onCloseBottomBarDrawer={variableChips?.onCloseBottomBarDrawer}
          drawer={variableChips?.bottomDrawer}
        >
          <ToolBarVariableButton group={variableChips.chips} editor={editor} />
        </BottomDrawer>
      )}
    </div>
  );
};

export default withGenerateClassName(RichTextEditorV2);
