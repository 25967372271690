import Icon from '../common/Icon';
import { TYPES } from '../utils/SlateUtilityFunctions';
const TOOLBAR_ELEMENT_NAME = {
  font_heading: 'font_heading',
  font_family: 'font_family',
  font_size: 'font_size',
  bold: 'bold',
  italic: 'italic',
  underline: 'underline',
  strike_through: 'strike_through',
  alignment: 'alignment',
  color: 'color',
  backgroung_color: 'backgroung_color',
  ordered_list: 'ordered_list',
  unordered_list: 'unordered_list',
  indent_right: 'indent_right',
  indent_left: 'indent_left',
  link: 'link',
  image: 'image',
  table: 'table',
  personalise: 'personalise',
  codeToText: 'codeToText',
};
const toolbarGroups = [
  [
    {
      id: 1,
      name: TOOLBAR_ELEMENT_NAME.font_heading,
      type: 'custom_dropdown',
      placeholder: 'Normal',
      isCollapsible: true,
      minWidth: '116.56px',
      options: [
        {
          id: '1',
          label: 'Normal',
          value: { type: 'block', format: 'normal' },
        },
        { id: '2', label: 'H1', value: { type: 'block', format: TYPES.H1 } },
        { id: '3', label: 'H2', value: { type: 'block', format: TYPES.H2 } },
        { id: '4', label: 'H3', value: { type: 'block', format: TYPES.H3 } },
        { id: '5', label: 'H4', value: { type: 'block', format: TYPES.H4 } },
        { id: '6', label: 'H5', value: { type: 'block', format: TYPES.H5 } },
        { id: '7', label: 'H6', value: { type: 'block', format: TYPES.H6 } },
        {
          id: '8',
          label: 'Blockquote',
          value: { type: 'block', format: TYPES.BLOCKQUOTE },
        },
        { id: '9', label: 'Code', value: { type: 'mark', format: 'code' } },
      ],
    },
    {
      id: 2,
      name: TOOLBAR_ELEMENT_NAME.font_family,
      isCollapsible: true,
      format: 'fontFamily',
      type: 'dropdown',
      options: [
        { id: 1, label: 'Sans Serif', value: 'sans' },
        { id: 2, label: 'Serif', value: 'serif' },
        { id: 3, label: 'MonoSpace', value: 'monospace' },
      ],
    },
    {
      id: 3,
      name: TOOLBAR_ELEMENT_NAME.font_size,
      isCollapsible: true,
      format: 'fontSize',
      type: 'dropdown',
      options: [
        { id: 1, label: 'Small', value: 'small' },
        { id: 2, label: 'Normal', value: 'normal' },
        { id: 3, label: 'Medium', value: 'medium' },
        { id: 4, label: 'Huge', value: 'huge' },
      ],
    },
  ],
  [
    {
      id: 3,
      name: TOOLBAR_ELEMENT_NAME.bold,
      format: 'bold',
      type: 'mark',
      title: 'Bold',
    },
    {
      id: 4,
      name: TOOLBAR_ELEMENT_NAME.italic,
      format: 'italic',
      type: 'mark',
      title: 'Italic',
    },
    {
      id: 5,
      name: TOOLBAR_ELEMENT_NAME.underline,
      format: 'underline',
      type: 'mark',
      title: 'Underline',
    },
    {
      id: 6,
      name: TOOLBAR_ELEMENT_NAME.strike_through,
      format: 'strikethrough',
      type: 'mark',
      title: 'Strikethrough',
    },
  ],
  [
    {
      id: 11,
      name: TOOLBAR_ELEMENT_NAME.alignment,
      type: 'custom_dropdown',
      placeholder: <Icon icon={TYPES.ALIGN_LEFT} />,
      title: 'Alignment',
      options: [
        {
          id: '2',
          label: <Icon icon={TYPES.ALIGN_LEFT} />,
          value: { type: 'block', format: TYPES.ALIGN_LEFT },
        },
        {
          id: '3',
          label: <Icon icon={TYPES.ALIGN_CENTER} />,
          value: { type: 'block', format: TYPES.ALIGN_CENTER },
        },
        {
          id: '4',
          label: <Icon icon={TYPES.ALIGN_RIGHT} />,
          value: { type: 'block', format: TYPES.ALIGN_RIGHT },
        },
      ],
    },
  ],
  [
    {
      id: 7,
      name: TOOLBAR_ELEMENT_NAME.color,
      isCollapsible: true,
      format: 'color',
      type: 'color-picker',
    },
    {
      id: 8,
      name: TOOLBAR_ELEMENT_NAME.backgroung_color,
      isCollapsible: true,
      format: 'bgColor',
      type: 'color-picker',
    },
  ],
  [
    {
      id: 15,
      name: TOOLBAR_ELEMENT_NAME.ordered_list,
      format: TYPES.ORDERED_LIST,
      type: 'block',
    },
    {
      id: 16,
      name: TOOLBAR_ELEMENT_NAME.unordered_list,
      format: TYPES.UNORDERED_LIST,
      type: 'block',
    },
    {
      id: 17,
      name: TOOLBAR_ELEMENT_NAME.indent_right,
      isCollapsible: true,
      format: 'indent-right',
      title: 'Insert sub-bullet',
      type: 'block',
    },
    {
      id: 18,
      name: TOOLBAR_ELEMENT_NAME.indent_left,
      isCollapsible: true,
      format: 'indent-left',
      title: 'Indent left / Remove bullet',
      type: 'block',
    },
  ],
  [
    {
      id: 20,
      name: TOOLBAR_ELEMENT_NAME.link,
      isCollapsible: true,
      format: TYPES.LINK,
      type: TYPES.LINK,
    },
    {
      id: 21,
      name: TOOLBAR_ELEMENT_NAME.image,
      isCollapsible: true,
      format: 'image',
      type: 'embed',
    },
    {
      id: 23,
      name: TOOLBAR_ELEMENT_NAME.table,
      isCollapsible: true,
      type: 'table',
    },
  ],
  [
    {
      id: 24,
      name: TOOLBAR_ELEMENT_NAME.personalise,
      type: 'custom_variableChips',
      title: 'Personalise',
      placeholder: <>Personalise</>,
    },
  ],
  // need this later for code to text
  // [
  //   {
  //     id: 27,
  //     name: TOOLBAR_ELEMENT_NAME.codeToText,
  //     isCollapsible: true,
  //     type: 'codeToText',
  //   },
  // ],
];

export default toolbarGroups;
