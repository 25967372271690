import { insertLink } from '../utils/link';
import { TYPES } from '../utils/SlateUtilityFunctions';
import isUrl from 'is-url';

const withLinks = (editor: any) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element: any) =>
    [TYPES.LINK, TYPES.CHIP].includes(element.type) || isInline(element);

  editor.insertText = (text: string) => {
    if (text && isUrl(text)) {
      insertLink(editor, { url: text });
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data: any) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      insertLink(editor, { url: text });
    } else {
      insertData(data);
    }
  };

  return editor;
};

export default withLinks;
