import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  fontBold: {
    fontWeight: 600,
  },
  variableButtons: {
    fontStyle: 'normal',
    fontFamily: 'Helvetica Neue',
    fontSize: '12px',
    minHeight: '20px',
    background: '#4893FF',
    borderRadius: '3px',
    letterSpacing: '0.02em',
    alignItems: 'center',
    padding: '0 4px',
    color: '#fff',
    display: 'inline-flex',
    userSelect: 'none',
    cursor: 'pointer',
    fontWeight: 500,
  },
  secondaryButton: {
    background: '#00B779',
  },
  rightMargin: {
    marginRight: '4px',
  },
}));
