import React from 'react';
import { useStyles } from './variableChip.styles';
import classnames from 'classnames';
interface VariableChipProps {
  attributes: any;
  element: any;
  value: string;
  secondaryColor?: string;
  variableChips?: any;
  readOnly?: boolean;
  showChipsOnReadOnly?: boolean;
  chipStyles?: React.CSSProperties;
}

const VariableChip: React.FC<VariableChipProps> = (props) => {
  const {
    attributes,
    element,
    children,
    variableChips,
    readOnly,
    showChipsOnReadOnly,
    chipStyles,
  } = props;
  const { chips } = variableChips || {};

  const elementOption =
    chips?.find((chip: any) => chip.value === element.value) || element;

  const css = useStyles();

  return (
    <>
      <cite
        contentEditable={false}
        className={classnames(
          css.rightMargin,
          readOnly && !showChipsOnReadOnly && css.fontBold,
          (!readOnly || showChipsOnReadOnly) && css.variableButtons,
          (!readOnly || showChipsOnReadOnly) &&
            elementOption.secondaryColor &&
            css.secondaryButton
        )}
        {...attributes}
        {...element.attr}
        style={{ fontStyle: 'normal', ...chipStyles }}
      >
        {children}
        {readOnly && !showChipsOnReadOnly
          ? elementOption.previewValue
          : elementOption.label}
      </cite>
    </>
  );
};

export default VariableChip;
